import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private readonly messageService: MessageService) {}

  addSuccess(body: { summary?: string; detail?: string }) {
    this.messageService.add({ severity: 'success', summary: body.summary, detail: body.detail });
  }

  addWarning(body: { summary?: string; detail?: string }) {
    this.messageService.add({ severity: 'warn', summary: body.summary, detail: body.detail });
  }

  addError(body: { summary?: string; detail?: string }) {
    this.messageService.add({ severity: 'error', summary: body.summary, detail: body.detail });
  }

  addInfo(body: { summary?: string; detail?: string }) {
    this.messageService.add({ severity: 'info', summary: body.summary, detail: body.detail });
  }
}
