import { Component, ElementRef, ViewChild } from '@angular/core';
import { LayoutService } from '../../../../core/layout/service/app.layout.service';
import { ToastService } from 'src/app/main/services/toast.service';

@Component({
  selector: 'app-main-topbar',
  templateUrl: './main-topbar.component.html',
})
export class MainTopbarComponent {
  @ViewChild('menubutton') menuButton!: ElementRef;
  tokens: string = '1000';

  constructor(private readonly layoutService: LayoutService, private readonly toastService: ToastService) {}

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onProfileButtonClick() {
    this.layoutService.showProfileSidebar();
  }

  checkUserToken() {
    this.toastService.addSuccess({
      detail: `You still have ${this.tokens} messages left `,
    });
  }
}
