import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UsersService } from '../../services/users.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';

@Directive({
  selector: '[glueLabsEnabled]',
})
export class GlueLabsEnabledDirective implements OnDestroy {
  private user?: User;
  userSubscription?: Subscription;

  constructor(
    private readonly ref: ViewContainerRef,
    private readonly templateRef: TemplateRef<HTMLElement>,
    private readonly usersService: UsersService
  ) {
    this.userSubscription = this.usersService.getUser$().subscribe({
      next: (u) => (this.user = u),
    });
  }

  @Input() set glueLabsEnabled(value: boolean) {
    this.ref.clear();
    if (environment.production) {
      if (!value || (this.user && environment.glueLabsCompanies.includes(this.user?.company.id))) {
        this.ref.createEmbeddedView(this.templateRef);
      } else {
        this.ref.clear();
      }
    } else {
      this.ref.createEmbeddedView(this.templateRef);
    }
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }
}
