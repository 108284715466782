import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, map } from 'rxjs';
import { AuthState, selectUser } from '../store/auth';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[level]',
})
export class LevelDirective implements OnDestroy {
  private subscription?: Subscription;

  private readonly user$ = this.store.select(selectUser);
  levelUser = environment.level.user.level;

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private readonly store: Store<{ auth: AuthState }>
  ) {}

  //todo -> permission optional
  //todo -> notlevel
  @Input() set level(role: number) {
    this.subscription = this.user$
      .pipe(
        map((user) => {
          if ((user?.company?.level ?? this.levelUser) >= role) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef);
          } else {
            this.viewContainer.clear();
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
