import { Component } from '@angular/core';
import { LayoutService } from '../../service/app.layout.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
})
export class AppTopbarComponent {
  constructor(public layoutService: LayoutService) {}

  onConfigButtonClick() {
    this.layoutService.showConfigSidebar();
  }
}
